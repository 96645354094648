<template>
  <div class="home">
    <banner isHome="true"></banner>
    <div class="site-content animate">
      <!--通知栏-->
      <div class="notify">
        <div class="search-result" v-if="hideSlogan">
          <span v-if="searchWords">搜索结果："{{searchWords}}" 相关文章</span>
          <span v-else-if="category">分类 "{{category}}" 相关文章</span>
        </div>
        <quote v-else>{{notice}}</quote>
      </div>

      <!--焦点图-->
      <div class="top-feature" v-if="!hideSlogan">
        <section-title>
          <div style="display: flex;align-items: flex-end;">聚焦<small-ico></small-ico></div>
        </section-title>
        <div class="feature-content">
          <div class="feature-item" v-for="item in features" :key="item.title">
            <Feature :data="item"></Feature>
          </div>
        </div>
      </div>
      <!--文章列表-->
      <main class="site-main" :class="{'search':hideSlogan}">
        <section-title v-if="!hideSlogan">推荐</section-title>
        <template v-for="item in postList">
          <post :post="item" :key="item.id"></post>
        </template>
      </main>

      <!--加载更多-->
      <div class="more" v-show="hasNextPage">
        <div class="more-btn" @click="loadMore">More</div>
      </div>
      <hr/>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner'
import Feature from '@/components/feature'
import sectionTitle from '@/components/section-title'
import Post from '@/components/post'
import SmallIco from '@/components/small-ico'
import Quote from '@/components/quote'
import {fetchFocus, fetchList} from '../api'

export default {
  name: 'Home',
  props: ['cate', 'words'],
  data() {
    return {
      features: [],
      postList: [],
      currPage: 1,
      hasNextPage: false,
      blogSearchForm:{
        pageNo:1,
        pageSize:10,
        blogTitle:'',
        typeName:'',
        searchWord:'',
      },
    }
  },
  components: {
    Banner,
    Feature,
    sectionTitle,
    Post,
    SmallIco,
    Quote
  },
  computed: {
    searchWords() {
      if (this.$route.params.words) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.blogSearchForm.typeName = '';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.blogSearchForm.searchWord = this.$route.params.words;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.postList = [];
        this.getBlogList();
      }
      return this.$route.params.words
    },
    category() {
      if (this.$route.params.cate) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.blogSearchForm.searchWord = '';
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.blogSearchForm.typeName = this.$route.params.cate;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.postList = [];
        this.getBlogList();
      }
      return this.$route.params.cate
    },
    hideSlogan() {
      return this.category || this.searchWords
    },
    notice() {
      return this.$store.getters.notice
    }
  },
  methods: {
    fetchFocus() {
      // fetchFocus().then(res => {
      //
      // }).catch(err => {
      //     console.log(err)
      // })
      this.features = [
        {
          id: 1,
          title: 'Akina',
          img: 'https://s1.ax1x.com/2020/05/14/YDfRnU.jpg'
        },
        {
          id: 2,
          title: '使用说明',
          img: 'https://s1.ax1x.com/2020/05/14/YDf4AJ.jpg'
        },
        {
          id: 3,
          title: '文章归档',
          img: 'https://s1.ax1x.com/2020/05/14/YDfT91.jpg'
        }
      ] || []
    },
    fetchList() {
      this.getBlogList();
    },
    loadMore() {
      this.blogSearchForm.pageNo ++;
      this.getBlogList();
    },

    clearCondition(){
      this.blogSearchForm = {
        blogTitle:'',
        typeId:'',
        pageNo: 1,
        pageSize: 10,
        searchWord:"",
      }
      this.getBlogList();

    },
    searchData(){
      this.blogSearchForm.pageNo = 1;
      this.blogSearchForm.pageSize = 10;
      this.postList = [];
      this.getBlogList();
    },
    async getBlogList(){
      const {data: res} = await this.$blog.post('/home/blog/getall',this.blogSearchForm)
      this.postList = this.postList.concat(res.data.records || []);
      this.currPage = res.data.current
      this.hasNextPage = res.data.current < res.data.pages
    },

  },
  watch: {
    // 监听路由参数的变化
    '$route'(to, from) {
      if (to.name === 'home') {
        if (from.name === 'category') {
          this.blogSearchForm.typeName = "";
          this.postList = [];
          this.getBlogList();
        } else if (from.name === 'search'){
          this.blogSearchForm.searchWord = "";
          this.postList = [];
          this.getBlogList();
        }
      }
    }
  },
  mounted() {
    this.fetchFocus();
    this.postList = [];
    if (this.blogSearchForm.typeName === '' && this.blogSearchForm.searchWord === '') {
      this.getBlogList();
    }
  }
}
</script>
<style scoped lang="less">

.site-content {
  .notify {
    margin: 60px 0;
    border-radius: 3px;
    & > div {
      padding: 20px;
    }
  }


  .search-result {
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border: 1px dashed #ddd;
    color: #828282;
  }
}

.top-feature {
  width: 100%;
  height: auto;
  margin-top: 30px;

  .feature-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .feature-item {
      width: 32.9%;
    }
  }
}

.site-main {
  padding-top: 80px;

  &.search {
    padding-top: 0;
  }
}

.more{
  margin: 50px 0;
  .more-btn{
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #ADADAD;
    border: 1px solid #ADADAD;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    &:hover{
      color: #8fd0cc;
      border: 1px dashed #8fd0cc;
    }
  }
}

/******/
@media (max-width: 800px) {
  .top-feature {
    display: none;
  }

  .site-main {
    padding-top: 40px;
  }

  .site-content {
    .notify {
      margin: 30px 0 0 0;
    }

    .search-result {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}

/******/
</style>
